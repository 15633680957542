const healthRoute = '/health';
      const hotline= "+201222190383";
      const mail = "info@decoworld.org";
      const whatsapp = "2482824071"; //mustn't have '+'
      const safaga =      "+201064211728";
      const portGhaleb =  "+201064211615";
      const seychelles =  "+248 2824071";
      const elGouna =  "+201222190383";
      const makadi =      "+201064211615";
      const facebook = "";
      
const string = Object.freeze({
    routes: {
      void:"javascript:;",
      topPage:"#",
      home: "/",
      about:'/about',
      health: healthRoute,
      training:'/training',
      csp:'/csp',
      media:'/media',
      supplies: '/supplies',
      locations: '/locations',
      partners: '/partners',
      doctors: '/doctors',
      healthDes: healthRoute+'/des',
      healthDm: healthRoute+'/dm',
      healthDci: healthRoute+'/dci',
      healthHbot: healthRoute+'/hbot',
      healthFtd: healthRoute+'/ftd',
      healthAcs: healthRoute+'/acs',
      login: '/login',
      center: '/guests',
      guest: '/g/:guestId',
      editInfo: '/center/edit-info',
    },
    contacts:{
      phone: hotline,
      hotline: hotline,
      mail: mail,
      whatsapp: `+${whatsapp}`,
      safaga: safaga,
      portGhaleb: portGhaleb,
      seychelles: seychelles,
      elGouna: elGouna,
      makadi: makadi,
      facebook: facebook,

      hotlineLink:`tel:${hotline}`,
      whatsappLink:`https://api.whatsapp.com/send?phone=${whatsapp}`,
      mailLink:`mailto:${mail}`,
      safagaLink:`tel:${safaga}`,
      elGounaLink:`tel:${elGouna}`,
      portGhalebLink:`tel:${portGhaleb}`,
      seychellesLink:`tel:${seychelles}`,
      makadiLink:`tel:${makadi}`,
    }
  })
  
  export default string
  