import { R } from '../../res'
import "./ui.styles.css"
const PhoneNumbersMenu = (props: { isDesktop: boolean, functionSetDropMenu: Function }) => {
    return (
        <div
            onMouseOver={() => props.functionSetDropMenu(true)}
            onMouseOut={() => props.functionSetDropMenu(false)}
            className="phoneNumbersMenu"
        >
            <div className="phoneNumbersMenuContent">

                <a href={R.string.contacts.hotlineLink}>HotLine : {R.string.contacts.hotline}</a>
                <a href={R.string.contacts.makadiLink}>Makadi : {R.string.contacts.makadi}</a>
                <a href={R.string.contacts.safagaLink}>Safaga : {R.string.contacts.safaga}</a>
                <a href={R.string.contacts.portGhalebLink}>Port Ghaleb : {R.string.contacts.portGhaleb}</a>
            </div>
        </div>
    )
}

export default PhoneNumbersMenu